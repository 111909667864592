import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Rule } from '@models/pro.model';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { TimelineService } from '@services/timeline/timeline.service';
import { NavigationService } from '@services/navigation.service';
import { ProService } from '@services/pro.service';
import { LoaderService } from '@services/loader.service';
import { ProductsService } from '@services/timeline/products.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-factor-calc',
  templateUrl: './factor-calc.component.html',
  styleUrls: ['./factor-calc.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FactorCalcComponent extends PropertiesBaseComponent implements OnChanges, AfterViewInit, OnInit {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public pagingService: PagingService,
    public timelineService: TimelineService,
    public navigationService: NavigationService,
    public proService: ProService,
    public loaderService: LoaderService,
    public productsService: ProductsService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    this.debugMode = this.route.snapshot.queryParams.debug;

    // this.subscriptions.add(
    // this.onLoad.subscribe(() => {
    //   this.results = this.resultsService.getFactors();
    //   console.info('****', 'sending factor data');
    //   this.productsService.reset();
    //   if (!this.debugMode) {
    //     this.moveOn();
    //   } else {
    //     this.loaderService.isReady();
    //     this._rules.forEach(rule => {
    //       (rule as any).valid = this.dryRun(rule);
    //     });
    //   }
    // }),
    // );

    this.subscriptions.add(
      this.route.params.subscribe(async params => {
        this.activeStepIndex = params.step_id;
      }),
    );
  }
  ngAfterViewInit(): void {
    this.results = this.resultsService.getFactors();
    console.info('****', 'sending factor data');
    this.productsService.reset();
    if (!this.debugMode) {
      this.moveOn();
    } else {
      this.loaderService.isReady();
      this._rules.forEach(rule => {
        (rule as any).valid = this.dryRun(rule);
      });
    }
  }

  activeStepIndex = 0;
  operatorSymbols: Record<string, string> = { eq: '=', gt: '>', bt: '< >', gte: '>=', lte: '=<', bte: '<==>' };
  public results: Record<string, number> = {};

  debugMode = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  factors: any[] = [];

  public ngOnInit() {
    this.factors = [];
    for (const factor in this._properties.factors) {
      this.factors.push(factor);
    }
  }

  moveOn() {
    const overrideNavigation = this.resultsService.processRules(
      this._rules,
      {
        result: this.results,
      },
      this.activeStepIndex,
    );

    this.dryRunResult = this.resultsService.processRulesGetResults(this._rules, {
      result: this.results,
    });

    if (this.dryRunResult) {
      this.proService.canSkipValue = true;
    }

    this.timelineService.mergeStep(Number(this.activeStepIndex), { ops: overrideNavigation?.ops } as any);

    this.onUpdate.next({
      questionId: this.question?._id,
      result: this.results,
    });

    this.loaderService.notReady();
    this.navigationService.movePage(true);
  }

  dryRunResult = undefined;

  dryRun(rule?: Rule) {
    if (rule) {
      rule.validationResult = this.resultsService.processRulesGetResults([rule], {
        result: this.results,
      });
    } else {
      this.dryRunResult = this.resultsService.processRulesGetResults(this._rules, {
        result: this.results,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateProps(event: any) {
    // this.onUpdate.next({ questionId: this.question._id, result: this.stepValue });
  }
}
