import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { Rule } from '@models/pro.model';
import { Question } from '@models/question.model';
import { DirtyService } from '@services/dirty.service';
import { PagingService } from '@services/paging.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-item-base',
  template: '',
})
export class PropertiesBaseComponent implements OnDestroy {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public pagingService: PagingService,
  ) {
    this.subscriptions.add(
      this.pagingService.configureEvent.subscribe(configuration => {
        console.info('Log: configureEvent canContinue', configuration.next);
        this.canContinue = configuration.next;
      }),
    );
  }

  canContinue = false;

  public subscriptions: Subscription = new Subscription();

  @Input('question') set question(value: Question | null) {
    if (value) {
      this._question = value;
      this._properties = value.properties || {};
      this._properties.options = this._properties.options || [];
      console.time('loaded');
      if (this._question) {
        this.onLoad.next(this._question);
        this.loaded(this._question);
        console.timeEnd('loaded');
      }
    }
  }

  get question(): Question | null {
    return this._question ? this._question : null;
  }

  get rules() {
    return this._rules;
  }

  @Input() set rules(value: Rule[]) {
    this._rules = value || [];
  }

  public DataController: any;

  protected loaded(question: Question) {
    console.info(question);
  }

  @Output() public onUpdate: Subject<Record<string, any>> = new Subject();

  @Output() public onLoad: Subject<Record<string, any>> = new Subject();

  public _properties: Record<string, any> = {};

  public stepValue: any;

  protected _question?: Question;

  protected _rules: Rule[] = [];

  public values: any = {};

  public rulesDictionary = {};

  public clearDirty() {
    this.dirtyService.clearDirty();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
