<ng-template #template>
  <a class="close-button cursor-btn" id="dialog-close-btn">
    <div class="countrer-close">
      <div class="child counter-part" [ngClass]="{ hidden: closeBtnVisible }">
        <app-countdown [close]="true" [closeOnly]="true" (complete)="closeDialog()"></app-countdown>
      </div>
    </div>
  </a>
  <div class="modal-body" *ngIf="activeTextBubble">
    <div class="title-flex">
      <img src="/assets/icons/light-bulb.svg" alt="light-bulb" class="light-bulb" />

      <div class="title2 title-space" [innerHTML]="activeTextBubble.prefix"></div>
    </div>

    <div class="title3" [innerHTML]="activeTextBubble.info"></div>
  </div>
</ng-template>

<ng-template #dosageModalTemplate>
  <div class="modal-body">
    <a (click)="closeDosageDialog(activeOption)" class="close-button" id="dialog-close-btn">
      <img src="../../../../assets/icons/close.svg" class="Group-74" alt="" />
    </a>
    <div class="dosage-title bold" [innerHTML]="activeOption.label"></div>

    <div class="clear">
      <div class="form-group pad">
        <div class="dosage-title">{{ activeOption.dosageLabel || 'Please specify the dosage for the selected medication.' }}</div>
        <select class="form-control" [(ngModel)]="activeDosage" id="dosageSelector">
          <option [value]="''" *ngIf="dosageOptions && dosageOptions.length > 1">Select dosage</option>
          <option *ngFor="let dosage of dosageOptions" [value]="dosage">{{ dosage }}</option>
        </select>
      </div>
    </div>
    <div class="clear">
      <div class="form-group pad">
        <div class="dosage-title">How long have you been taking this medication?</div>
        <select class="form-control" [(ngModel)]="activeDuration" id="durationSelector">
          <option [value]="''">Select duration</option>
          <option *ngFor="let dosage of durations" [value]="dosage.value">{{ dosage.label }}</option>
        </select>
      </div>
    </div>

    <div class="form-group pad">
      <button id="actionButtonDosage" [disabled]="!activeOption.dosage" class="btn btn-primary btn-100" (click)="updateDosage()">Select</button>
    </div>
  </div>
</ng-template>

<div *ngIf="optionsArray" class="panel centered" #container>
  <table>
    <thead>
      <tr>
        <th class="col-6"></th>

        <ng-container *ngFor="let subDimension of _properties.subDimensions">
          <th class="col-1 capital text-center">{{ subDimension.title }}</th>
          <th class="col-1"></th>
        </ng-container>
      </tr>
    </thead>
    <tbody
      [ngClass]="{ small: _properties.subDimensions?.length === 1, active: optionsArray[i] && optionsArray[i].isActive }"
      *ngFor="let option of optionsArray; let i = index"
      (click)="setActiveOptionHighlight(i)"
    >
      <tr>
        <td class="med-label col-6">
          {{ optionsArray[i].label }}
          <div class="dosage-label" *ngIf="optionsArray[i].dosageApplied">
            {{ optionsArray[i].dosageApplied }} <br />

            {{ optionsArray[i].durationApplied }}
          </div>
        </td>
        <ng-container *ngFor="let subDimension of _properties.subDimensions">
          <td class="col-1 text-center">
            <input
              [title]="optionsArray[i].label"
              class="form-check-input check-lg {{ subDimension.name }}"
              type="checkbox"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="optionsArray[i][subDimension.name]"
              id="{{ subDimension.name + i }}"
              (change)="showModalForOption(i, optionsArray[i], subDimension.name)"
            />
          </td>
          <td class="col-1"></td>
        </ng-container>
      </tr>
    </tbody>
  </table>

  <div class="center">
    <button id="actionButton" [disabled]="!canContinue" class="btn btn-primary btn-lg" (click)="moveNext()" [innerHTML]="_properties.text1"></button>
  </div>
</div>
